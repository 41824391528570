import axios from 'axios';

export const api = axios.create();

api.interceptors.response.use(config => config, error => {
    if(error.response?.status === 419) {
        alert(`Votre session n'est plus valide, appuyez sur Ok pour actualiser la page`);
        location.reload();
    }
    return Promise.reject(error);
});
